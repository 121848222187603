html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

html {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

html {
  --splash-image: url(/public/iphone6_splash.png);
  --splash-background-color: #D63839;
  --splash-text-color: #FFF;
}

body {
  flex: 1;
}

form {
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px;
}